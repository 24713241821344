import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class ModulesService {
  private apiPrefix = '/client/modules';
  private cache: { [key: number]: BehaviorSubject<any> } = {};

  constructor(
    private api: ApiService
  ) {
  }

  getModuleVisibilityForPeriod(periodId: number): Observable<any> {
    if (!this.cache[periodId]) {
      this.cache[periodId] = new BehaviorSubject<any>(null);
      this.api.get(`${this.apiPrefix}/${periodId}/visibility`)
        .pipe(tap(response => this.cache[periodId].next(response)))
        .subscribe();
    }

    return this.cache[periodId].asObservable();
  }
}
