import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./core/guards/auth.guard";
import { DefaultLayoutComponent } from "./views/layout/default-layout/default-layout.component";
import { OnboardingLayoutComponent } from "./views/layout/onboarding-layout/onboarding-layout.component";
import { LandingLayoutComponent } from "./views/layout/landing-layout/landing-layout.component";
import { NotCompletedOnboardingGuard } from "./core/guards/onboarding/not-completed-onboarding.guard";
import { CompletedOnboardingGuard } from "./core/guards/onboarding/completed-onboarding.guard";
import { EmailVerifyGuard } from "./core/guards/email-verify.guard";
import { EAppModules } from "./core/enums/app-modules.enum";
import { ModuleVisibilityGuard } from "./core/guards/module-visibility.guard";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'auth',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: LandingLayoutComponent,
    loadChildren: () => import('./views/pages/landing/landing.module').then(m => m.LandingModule),
    data: { meta: { name: 'robots', content: 'index, follow' } }
  },
  {
    path: 'blog',
    component: LandingLayoutComponent,
    loadChildren: () => import('./views/pages/blog/blog.module').then(m => m.BlogModule),
    data: { meta: { name: 'robots', content: 'index, follow' } }
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard, EmailVerifyGuard, CompletedOnboardingGuard],
    loadChildren: () => import('./views/pages/user-onboarding/user-onboarding.module').then(m => m.UserOnboardingModule),
  },
  {
    path: 'payment',
    component: OnboardingLayoutComponent,
    canActivate: [AuthGuard, EmailVerifyGuard],
    loadChildren: () => import('./views/pages/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, EmailVerifyGuard, NotCompletedOnboardingGuard],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'videos',
        loadChildren: () => import('./views/pages/video/video.module').then(m => m.VideoModule),
        data: {moduleKey: EAppModules.VIDEO_LIVESTREAM},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'user-manual',
        loadChildren: () => import('./views/pages/user-manual/user-manual.module').then(m => m.UserManualModule),
        data: {moduleKey: EAppModules.USER_MANUAL},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'exam-analysis',
        loadChildren: () => import('./views/pages/exam-analysis/exam-analysis.module').then(m => m.ExamAnalysisModule),
        data: {moduleKey: EAppModules.DAS},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'exam-mentors',
        loadChildren: () => import('./views/pages/exam-mentor/exam-mentor.module').then(m => m.ExamMentorModule),
        data: {moduleKey: EAppModules.EXAM_MENTORS},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./views/pages/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'planner',
        loadChildren: () => import('./views/pages/planner/planner.module').then(m => m.PlannerModule),
        data: {moduleKey: EAppModules.PLANNER},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'documents',
        loadChildren: () => import('./views/pages/documents/documents.module').then(m => m.DocumentsModule),
        data: {moduleKey: EAppModules.DOCUMENTS},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'blog',
        loadChildren: () => import('./views/pages/blog/blog.module').then(m => m.BlogModule),
        data: {moduleKey: EAppModules.BLOG},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'workbooks',
        loadChildren: () => import('./views/pages/workbook/workbook.module').then(m => m.WorkbookModule),
        data: {moduleKey: EAppModules.WORKBOOK},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/pages/notification/notification.module').then(m => m.NotificationModule),
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./views/pages/campaign/campaign.module').then(m => m.CampaignModule),
        data: {moduleKey: EAppModules.CAMPAIGNS},
        canActivate: [ModuleVisibilityGuard],
      },
      {
        path: 'community',
        loadChildren: () => import('./views/pages/community/community.module').then(m => m.CommunityModule),
        data: {moduleKey: EAppModules.COMMUNITY},
        canActivate: [ModuleVisibilityGuard],
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'dashboard' // TODO add 404 page
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
