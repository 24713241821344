export enum EAppModules {
  PLANNER = 'PLANNER',
  WORKBOOK = 'WORKBOOK',
  VIDEO_LIVESTREAM = 'VIDEO_LIVESTREAM',
  DAS = 'DAS',
  EXAM_MENTORS = 'EXAM_MENTORS',
  DOCUMENTS = 'DOCUMENTS',
  BLOG = 'BLOG',
  COMMUNITY = 'COMMUNITY',
  USER_MANUAL = 'USER_MANUAL',
  CAMPAIGNS = 'CAMPAIGNS',
}
