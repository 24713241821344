import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { NgbModal, NgbOffcanvas, NgbOffcanvasConfig } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, takeUntil } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { ScrollService } from "../../../core/services/scroll.service";
import { isPlatformBrowser } from "@angular/common";
import { IUser } from "../../../core/models/user/user.model";
import { selectCurrentUser } from "../../../core/ngrx/selectors/user.selectors";
import { Store } from "@ngrx/store";
import { UserState } from "../../../core/ngrx/reducers/user.reducer";
import { cdnUri } from "../../../core/helpers/app.helper";
import { ModulesService } from "../../../core/services/application/modules.service";
import { IModuleVisibility } from "../../../core/models/application/module-visibility.model";
import { EAppModules } from "../../../core/enums/app-modules.enum";
import { PeriodPlanService } from "../../../core/services/payment/period-plan.service";
import { IPeriodPaymentPlanInfo } from "../../../core/models/payment/period-payment-plan-info.model";
import { IPeriod } from "../../../core/models/exam/exam-period.model";
import { StorageService } from "../../../core/services/storage.service";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrl: './default-layout.component.scss'
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  appPages = [
    {
      title: 'Anasayfa',
      url: '/dashboard',
      icon: 'home-thin',
      grayIcon: true,
      active: true
    },
    {
      title: 'Planlayıcı',
      url: '/dashboard/planner',
      icon: 'calendar',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.PLANNER,
    },
    {
      title: 'Konumetre',
      url: '/dashboard/workbooks',
      icon: 'workbook',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.WORKBOOK,
    },
    {
      title: 'Videolar / Canlı Yayınlar',
      url: '/dashboard/videos',
      icon: 'videos-thin',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.VIDEO_LIVESTREAM,
    },
    {
      title: 'Deneme Analiz Sistemi',
      url: '/dashboard/exam-analysis',
      icon: 'analysis',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.DAS,
    },
    {
      title: 'NaviDerece',
      url: '/dashboard/exam-mentors',
      icon: 'brain',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.EXAM_MENTORS,
    },
    {
      title: 'Dokümanlar',
      url: '/dashboard/documents',
      icon: 'document-thin',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.DOCUMENTS,
    },
    {
      title: 'Blog',
      url: '/dashboard/blog',
      icon: 'blog',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.BLOG,
    },
    {
      title: 'Topluluk',
      url: '/dashboard/community',
      icon: 'handshake',
      grayIcon: true,
      active: false,
      moduleKey: EAppModules.COMMUNITY,
    },
    {
      title: 'Kullanım Kılavuzu',
      url: '/dashboard/user-manual',
      icon: 'bi bi-question-circle-fill',
      grayIcon: false,
      active: false,
      moduleKey: EAppModules.USER_MANUAL,
      // isExternal: true,
    },
    {
      title: 'Hesap',
      url: '/dashboard/account',
      icon: 'user-menu',
      grayIcon: true,
      active: false
    },
    // {
    //   title: 'Kendini Tanı',
    //   url: '/settings',
    //   icon: 'know-yourself',
    //   active: false
    // },
  ];
  menuIsCollapsed = false;
  innerWidth?: number;
  isBrowser: boolean;
  loggedIn = false;
  user$: Observable<IUser | null>;
  user?: IUser;
  moduleVisibilities?: IModuleVisibility;
  appModules = EAppModules;
  periodsInfo?: IPeriodPaymentPlanInfo;
  selectablePeriods?: IPeriod[] = [];
  selectedPeriod?: IPeriod;
  destroyed$ = new Subject();

  constructor(
    config: NgbOffcanvasConfig,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    private store: Store<UserState>,
    private modalService: NgbModal,
    private moduleService: ModulesService,
    private periodPlanService: PeriodPlanService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private scrollService: ScrollService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.user$ = store.select(selectCurrentUser);
    config.position = 'start';
    config.keyboard = false;
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        this.appPages = this.appPages.map(p => {
          return {
            ...p,
            active: p.url === event.url
          }
        });

        this.menuIsCollapsed = ((!this.innerWidth) || (this.innerWidth >= 768)) && event.url !== '/dashboard';
      }
    });
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;

      this.user$.pipe(takeUntil(this.destroyed$))
        .subscribe(r => {
          this.user = r as IUser;
          this.setModuleVisibility();
        });

      this.periodPlanService.getListedPeriodAndPrices().pipe(takeUntil(this.destroyed$))
        .subscribe(r => {
          this.periodsInfo = r.data;
          this.selectablePeriods = this.periodsInfo?.periods.filter(p => this.periodsInfo?.userPeriodIds.includes(p.id));
          const selectedPeriodId = Number(this.periodPlanService.getSelectedPeriodId());
          this.selectedPeriod = this.selectablePeriods?.find(p => p.id === selectedPeriodId);
        })
    }

  }

  selectedPeriodChanged(e: any) {
    this.periodPlanService.setSelectedPeriodId(e);
    if (this.isBrowser) {
      window.location.reload();
    }
  }

  setModuleVisibility() {
    this.moduleService.getModuleVisibilityForPeriod(this.user?.userInfo?.periodId as number).pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        if (r?.data) {
          this.moduleVisibilities = r.data as IModuleVisibility;
          // @ts-ignore
          this.appPages = this.appPages.filter(p => (!p?.moduleKey) || (p?.moduleKey && this.moduleVisibilities[p.moduleKey]));
        }
      })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  goToPage(p: any) {
    if (p.isExternal) {

      if (this.isBrowser && (typeof window !== 'undefined')) {
        window.open(p.url, '_blank');
      }

    } else {
      this.router.navigateByUrl(p.url)
    }
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content);
  }

  goToPayment() {
    this.router.navigateByUrl('/payment')
  }

  goToCampaigns() {
    this.router.navigateByUrl('/dashboard/campaigns');
  }

  onContainerScroll(event: any) {
    // console.log('scrolled');
    // console.log(event.target.scrollTop);
    this.scrollService.updateScrollPosition('defaultLayoutContainer', event.target.scrollTop);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  protected readonly cdnUri = cdnUri;
}
