import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { SharedModule } from "../../shared/shared.module";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbCollapseModule, NgbDropdownModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { UserProfileComponent } from './default-layout/user-profile/user-profile.component';
import { SearchInputComponent } from './default-layout/search-input/search-input.component';
import { TranslateModule } from "@ngx-translate/core";
import { LevelIndicatorComponent } from './default-layout/level-indicator/level-indicator.component';
import { NavNotificationsComponent } from './default-layout/nav-notifications/nav-notifications.component';
import { SharedNotificationModule } from "../../shared/modules/shared-notification/shared-notification.module";
import { OnboardingLayoutComponent } from './onboarding-layout/onboarding-layout.component';
import { LandingLayoutComponent } from './landing-layout/landing-layout.component';
import {LevelIndicatorTwoComponent} from './default-layout/level-indicator-two/level-indicator-two.component';
import { NgSelectModule } from "@ng-select/ng-select";


@NgModule({
  declarations: [
    DefaultLayoutComponent,
    UserProfileComponent,
    SearchInputComponent,
    LevelIndicatorComponent,
    NavNotificationsComponent,
    OnboardingLayoutComponent,
    LandingLayoutComponent,
    LevelIndicatorTwoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    TranslateModule,
    NgbDropdownModule,
    SharedNotificationModule,
    NgbCollapseModule,
    NgSelectModule,
  ]
})
export class LayoutModule {
}
