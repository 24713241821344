import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ModulesService } from "../services/application/modules.service";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { catchError, map, Observable, of, switchMap, take } from "rxjs";
import { IUser } from "../models/user/user.model";
import { Store } from "@ngrx/store";
import { UserState } from "../ngrx/reducers/user.reducer";
import { selectCurrentUser } from "../ngrx/selectors/user.selectors";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class ModuleVisibilityGuard implements CanActivate {
  user$: Observable<IUser | null>;
  user?: IUser;

  constructor(
    private modulesService: ModulesService,
    private router: Router,
    private store: Store<UserState>,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
    this.user$ = store.select(selectCurrentUser);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!isPlatformBrowser(this.platform)) {
      return of(false);
    }

    const moduleKey = route.data["moduleKey"];

    if (!moduleKey) {
      return of(true);
    }

    return this.user$.pipe(
      take(1),
      switchMap(user => {
        if (!user || !user?.userInfo?.periodId) {
          return of(this.router.createUrlTree(['/dashboard']));
        }

        return this.modulesService.getModuleVisibilityForPeriod(user.userInfo.periodId).pipe(
          map((visibility) => {
            const visibilityData = visibility.data;
            if (visibilityData && visibilityData[moduleKey]) {
              return true;
            }

            return this.router.createUrlTree(["/dashboard"]);
          }),
          catchError(() => of(this.router.createUrlTree(['/dashboard'])))
        );
      })
    );
  }
}
